import React from 'react';
//import { useNavigate } from "react-router-dom";
const categories = [
  {
    name: 'Earings',
    image:   '/assets/category/Earrings.jpg', // Replace with actual image URL
    href: '/product/?value=Earrings&name=Earrings'
  },
  {
    name: 'NeckSet',
    image: '/assets/category/Neckwear.jpg', // Replace with actual image URL
    href: '/product/?value=Neckwear&name=Neckwear/Mala'
  },
  {
    name: 'Bangle',
    image: '/assets/category/Bangle.jpg', // Replace with actual image URL 
    href: '/product/?value=Bangles&name=Bracelets/Bangles'
  },
  // {
  //   name: 'Clutch',
  //   image: '/assets/images/necklace4.jpg', // Replace with actual image URL
  //   href: '/product/?value=neckwearmala&name=clutch'
  // },
  // {
  //   name: 'Rings',
  //   image: '/assets/images/necklace1.jpg', // Replace with actual image URL
  //   href: '/product/?value=neckwearmala&name=Rings'
  // },
];

const Categories = () => {
  //const navigate = useNavigate();
 
    return (
        <div className="max-w-7xl mx-auto px-4 py-8">
          <h2 className="text-3xl font-bold text-center mb-8">Shop by Category</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
            {categories.map((category, index) => (
              <div
                key={index}
               
                className="cursor-pointer flex flex-col items-center text-center p-4w bg-gray-50 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
              >
                <a href={category.href}>
                <img
                  src={category.image}
                  alt={category.name}
                  className="h-52 w-40q object-contain mb-4w rounded-lg"
                /></a>
                <h3 className="text-lg font-semibold">{category.name}</h3>
              </div>
            ))}
          </div>
        </div>
      );
    // return (
    //     <div className="container mx-auto py-10">
    //       <h2 className="text-3xl font-bold text-center mb-8">Shop by Category</h2>
    //       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
    //         {categories.map((category, index) => (
    //           <div key={index} className="relative rounded-lg overflow-hidden shadow-lg group">
    //             {/* Image */}
    //             <img
    //               src={category.image}
    //               alt={category.name}
    //               className="w-full h-64 object-cover rounded-lg transition-transform duration-300 group-hover2:scale-105"
    //             />
    //             {/* Text Overlay */}
    //             <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity1-0 group-hover2:opacity-100 transition-opacity1 duration1-300">
    //               <h3 className="text-white text-2xl font-bold">{category.name}</h3>
    //             </div>
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //   );
};

export default Categories;
