import React,{useEffect} from "react";
import { useLocation,useNavigate } from "react-router-dom";
const ThankYouPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    debugger;
    const { orderId,amount,estimatedDelivery  } = location.state || {}; // Get the passed product object
    useEffect(() => {
        // Replaces the current history entry
        navigate("/orderplace",{ state: { orderId,amount,estimatedDelivery } , replace: true });
    
        // Optional: Prevent user from navigating back to order place page
        window.onpopstate = (event) => {
          // Optional: You can add a custom alert here
          navigate("/orderplace");
        };
    
        return () => {
          window.onpopstate = null; // Cleanup the effect on unmount
        };
      }, [navigate]);
      useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []); // Empty dependency array ensures it runs only on mount
    if (!orderId) {
        return <div>No order placed</div>;
      }

      
  return (
    <div className="min-h-screenold flex items-center justify-center bg-gray-100" style={{minHeight:"80vh"}}>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
        {/* Thank You Header */}
        <h1 className="text-3xl font-semibold text-green-600 mb-4">
          Thank You for Your Order!
        </h1>

        {/* Order confirmation text */}
        <p className="text-gray-600 mb-6">
          Your order <span className="font-semibold">#{orderId}</span> has
          been successfully placed.Your order will be confirmed once you receive a confirmation email from aarangjewels@gmail.com
        </p>

        {/* Order Details */}
        <div className="text-left mb-6">
          <h2 className="text-lg font-semibold text-gray-800 mb-2">Order Summary</h2>
          <div className="text-sm text-gray-600 mb-2">
            <p>Order Number: <span className="font-semibold">{orderId}</span></p>
            <p>Total Price: <span className="font-semibold">{amount}</span></p>
            <p>Estimated Delivery: <span className="font-semibold">{estimatedDelivery}</span></p>
          </div>
        </div>

        {/* Continue Shopping Button */}
        <button
          onClick={() => window.location.href = "/"}
          className="bg-blue-600 text-white px-6 py-2 rounded-md text-lg font-medium hover:bg-blue-700 transition duration-300"
        >
          Continue Shopping
        </button>

        {/* Help/Support Section */}
        <div className="mt-6 text-gray-500 text-sm">
          <p>If you have any questions, please contact us at:</p>
          <p className="font-semibold text-gray-700">support@aarangjewels.com</p>
        </div>
      </div>
    </div>
  );
};

// const App = () => {
//   return (
//     <ThankYouPage
//       orderNumber="123456"
//       totalPrice="299.99"
//       estimatedDelivery="October 25, 2024"
//     />
//   );
// };

export default ThankYouPage;
