
import { useState,useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, FunnelIcon, MinusIcon, PlusIcon, Squares2X2Icon } from '@heroicons/react/20/solid'
import ProductList from '../ProductList';
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import * as XLSX from 'xlsx';
// const sortOptions = [
//   { name: 'Most Popular', href: '#', current: true ,value:'popularity'},
//   { name: 'Best Rating', href: '#', current: false,value:'rating' },
//   { name: 'Newest', href: '#', current: false ,value:'date'},
//   { name: 'Price: Low to High', href: '#', current: false,value:'price-asc' },
//   { name: 'Price: High to Low', href: '#', current: false,value:'price-desc' },
// ]
const subCategories = [
  { name: 'Totes', href: '#' },
  { name: 'Backpacks', href: '#' },
  { name: 'Travel Bags', href: '#' },
  { name: 'Hip Bags', href: '#' },
  { name: 'Laptop Sleeves', href: '#' },
]
const filtersold = [
  {
    id: 'color',
    name: 'Color',
    options: [
      { value: 'white', label: 'White', checked: false },
      { value: 'beige', label: 'Beige', checked: false },
      { value: 'blue', label: 'Blue', checked: true },
      { value: 'brown', label: 'Brown', checked: false },
      { value: 'green', label: 'Green', checked: false },
      { value: 'purple', label: 'Purple', checked: false },
    ],
  },
  {
    id: 'category',
    name: 'Category',
    options: [
      { value: 'new-arrivals', label: 'New Arrivals', checked: false },
      { value: 'sale', label: 'Sale', checked: false },
      { value: 'travel', label: 'Travel', checked: true },
      { value: 'organization', label: 'Organization', checked: false },
      { value: 'accessories', label: 'Accessories', checked: false },
    ],
  },
  {
    id: 'size',
    name: 'Size',
    options: [
      { value: '2l', label: '2L', checked: false },
      { value: '6l', label: '6L', checked: false },
      { value: '12l', label: '12L', checked: false },
      { value: '18l', label: '18L', checked: false },
      { value: '20l', label: '20L', checked: false },
      { value: '40l', label: '40L', checked: true },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const productsold = [
  // { id: 2, name: 'Product 2', price: 19.99, imageUrl: 'https://via.placeholder.com/150' },
  // { id: 3, name: 'Product 3', price: 39.99, imageUrl: 'https://via.placeholder.com/150' },
  // { id: 1, name: 'Product 1', price: 29.99, imageUrl: 'https://via.placeholder.com/150' },
      
      {
        category:'Earrings',
        id: 1,
        name: "Pink Yasmeen Jhumka",
        description:`Description of the product goes here. Its a great product that you'll love!`,
        originalPrice: 1050,
        discountPrice: 545,
        imageUrl: "necklace1",
        hoverImageUrl: "https://via.placeholder.com/150",
        popularity: 150, rating: 4.5, date: '2024-01-10' ,
        qty:1
      },
      {
        category:'Earrings',        
        id: 2,
        name: "Avya Designer Earring",
        description:`Description of the product goes here. Its a great product that you'll love!`,
        originalPrice: 885,
        discountPrice: 445,
        imageUrl: "necklace2",
        hoverImageUrl: "https://example.com/hover-image2.jpg",
        popularity: 300, rating: 4.0, date: '2023-12-22'
        ,qty:1
      },
      {
        category:'Earrings',
        id: 3,
        name: "Pink Yasmeen Jhumka",
        description:`Description of the product goes here. Its a great product that you'll love!`,
        originalPrice: 1050,
        discountPrice: 545,
        imageUrl: "necklace3",
        hoverImageUrl: "https://via.placeholder.com/150",
        popularity: 150, rating: 4.5, date: '2024-01-10' 
        ,qty:1
      },
      {
        category:'Earrings',
        id: 4,
        name: "Pink Yasmeen Jhumka",
        description:`Description of the product goes here. Its a great product that you'll love!`,
        originalPrice: 1050,
        discountPrice: 545,
        imageUrl: "necklace4",
        hoverImageUrl: "https://via.placeholder.com/150",
        popularity: 300, rating: 4.0, date: '2023-12-22'
        ,qty:1
      },
      {
        category:'Earrings',
        id: 5,
        name: "Pink Yasmeen Jhumka",
        description:`Description of the product goes here. Its a great product that you'll love!`,
        originalPrice: 1050,
        discountPrice: 545,
        imageUrl: "",
        hoverImageUrl: "https://via.placeholder.com/150",
        popularity: 250, rating: 3.8, date: '2023-10-15'
        ,qty:1
      },
      {
        category:'noserings',
        id: 6,
        name: "Avya Designer Earring",
        originalPrice: 885,
        discountPrice: 445,
        imageUrl: "https://example.com/image2.jpg",
        hoverImageUrl: "https://example.com/hover-image2.jpg",
        popularity: 50, rating: 5.0, date: '2024-02-05'
        ,qty:1
      },
      {
        category:'braceletsbangles',
        id: 7,
        name: "Pink Yasmeen Jhumka",
        originalPrice: 1050,
        discountPrice: 545,
        imageUrl: "''",
        hoverImageUrl: "https://via.placeholder.com/150",
        popularity: 250, rating: 3.8, date: '2023-10-15'
        ,qty:1
      },
      {
        category:'braceletsbangles',
        id: 8,
        name: "Pink Yasmeen Jhumka",
        originalPrice: 1050,
        discountPrice: 545,
        imageUrl: "''",
        hoverImageUrl: "https://via.placeholder.com/150",
        popularity: 50, rating: 5.0, date: '2024-02-05'
        ,qty:1
      },
      {
        category:'neckwearmala',
        id: 9,
        name: "Pink Yasmeen Jhumka",
        originalPrice: 1050,
        discountPrice: 545,
        imageUrl: "''",
        hoverImageUrl: "https://via.placeholder.com/150",
        popularity: 250, rating: 3.8, date: '2023-10-15'
        ,qty:1
      },
      {
        category:'neckwearmala',
        id: 10,
        name: "Avya Designer Earring",
        description:`Description of the product goes here. Its a great product that you'll love!`,
        originalPrice: 885,
        discountPrice: 445,
        imageUrl: "https://example.com/image2.jpg",
        hoverImageUrl: "https://example.com/hover-image2.jpg",
        popularity: 250, rating: 3.8, date: '2023-10-15'
        ,qty:1
      },
      {
        category:'Earrings',
        id: 11,
        name: "Pink Yasmeen Jhumka",
        originalPrice: 1050,
        discountPrice: 545,
        imageUrl: "''",
        hoverImageUrl: "https://via.placeholder.com/150",
        popularity: 250, rating: 3.8, date: '2023-10-15'
        ,qty:1
      },
      {
        category:'neckwearmala',
        id: 12,
        name: "Pink Yasmeen Jhumka",
        originalPrice: 1050,
        discountPrice: 545,
        imageUrl: "''",
        hoverImageUrl: "https://via.placeholder.com/150",
        popularity: 250, rating: 3.8, date: '2023-10-15'
        ,qty:1
      }
      // Add more products here
  
];
const ITEMS_PER_PAGE = 4;
export default function ProductHome() {
  const location = useLocation();
  const [value, setValue] = useState('');
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
    
  useEffect(() => {
    // Fetch the Excel file from the public assets folder
    fetch('/assets/products.xlsx')
      .then(response => response.arrayBuffer())
      .then(data => {
        debugger;
        // Read the Excel file
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[1]; // Get the first sheet
        const worksheet = workbook.Sheets[sheetName];
        
        // Convert sheet to JSON
        const json = XLSX.utils.sheet_to_json(worksheet);
        debugger;
        setProducts(json.filter(f=>f.active.toLocaleLowerCase()=='y'));
      })
      .catch(error => console.error('Error fetching the Excel file:', error));
  }, []);
  useEffect(() => {
    // Create an instance of URLSearchParams from the location search (query string)
    const params = new URLSearchParams(location.search);
    // Get the value of the specific query parameter (e.g., "name")
    const queryValue = params.get('value'); // replace 'name' with the actual query parameter key
    const queryName = params.get('name');
    const queryCat = params.get('loadcategory');
    setValue(queryValue);
    setName(queryName);
    setCategory(queryCat);
  }, [location.search]); // Re-run when the query string changes

  const [sortingOptions, setSortingOptions] = useState([
    { name: 'Most Popular', href: '#', current: true ,value:'popularity'},
    { name: 'Best Rating', href: '#', current: false,value:'rating' },
    { name: 'Newest', href: '#', current: false ,value:'date'},
    { name: 'Price: Low to High', href: '#', current: false,value:'price-asc' },
    { name: 'Price: High to Low', href: '#', current: false,value:'price-desc' },
  ]);
  const [filters,setFilters] = useState([
    {
      id: 'color',
      name: 'Color',
      options: [
        { value: 'white', label: 'White', checked: false },
        { value: 'beige', label: 'Beige', checked: false },
        { value: 'blue', label: 'Blue', checked: false },
        { value: 'brown', label: 'Brown', checked: false },
        { value: 'green', label: 'Green', checked: false },
        { value: 'purple', label: 'Purple', checked: false },
      ],
    },
    {
      id: 'category',
      name: 'Category',
      options: [
        { value: 'new-arrivals', label: 'New Arrivals', checked: false },
        { value: 'sale', label: 'Sale', checked: false },
        { value: 'travel', label: 'Travel', checked: false },
        { value: 'organization', label: 'Organization', checked: false },
        { value: 'accessories', label: 'Accessories', checked: false },
      ],
    },
    {
      id: 'size',
      name: 'Size',
      options: [
        { value: '2l', label: '2L', checked: false },
        { value: '6l', label: '6L', checked: false },
        { value: '12l', label: '12L', checked: false },
        { value: '18l', label: '18L', checked: false },
        { value: '20l', label: '20L', checked: false },
        { value: '40l', label: '40L', checked: false },
      ],
    },
  ]);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const [priceRange, setPriceRange] = useState([0, 0]); // Min and Max price
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [sortOption, setSortOption] = useState('price-asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(6); // Items per page
 
  const [currentProducts, setCurrentProducts] = useState([]); 

debugger;
  // Filter logic
  // const filteredProductsold = products
  //   .filter((product)=>product.category==value)
  //   .filter(product =>(value? product.category === value:product.loadcategory===category))
  //   .filter((product) =>( priceRange[1]>0 ? product.price >= priceRange[0] && product.price <= priceRange[1]:true))
  //   .filter((product) => (selectedColors.length ? selectedColors.includes(product.specifications_Color) : true))
  //   .filter((product) => (selectedSizes.length ? selectedSizes.includes(product.specifications_Size) : true))
  //   .sort((a, b) => {
  //     if (sortOption === 'price-asc') return a.price - b.price;
  //     if (sortOption === 'price-desc') return b.price - a.price;
  //     if (sortOption === 'popularity') return b.popularity - a.popularity; 
  //     if (sortOption === 'rating') return b.rating - a.rating;
  //     if (sortOption === 'date') return new Date(b.date) - new Date(a.date);    
  //    // if (sortOption === 'name-asc') return a.name.localeCompare(b.name);
  //     return b.name.localeCompare(a.name);
  //   });
    useEffect(() => {
      debugger;
      // Filter and sort products whenever products or filter criteria change
      const filtered = products
        .filter(product =>(value? product.category === value:product.loadcategory===category))
        .filter(product => (priceRange[1] > 0 ? product.discountPrice >= priceRange[0] && product.discountPrice <= priceRange[1] : true))
        .filter(product => (selectedColors.length ? selectedColors.includes(product.specifications_Color) : true))
        .filter(product => (selectedSizes.length ? selectedSizes.includes(product.specifications_Size) : true))
        .sort((a, b) => {
          if (sortOption === 'price-asc') return a.discountPrice - b.discountPrice;
          if (sortOption === 'price-desc') return b.discountPrice - a.discountPrice;
          if (sortOption === 'popularity') return b.popularity - a.popularity;
          if (sortOption === 'rating') return b.rating - a.rating;
          if (sortOption === 'date') return new Date(b.date) - new Date(a.date);
          return a.name.localeCompare(b.name);
        });
  
      setFilteredProducts(filtered); // Update filtered products
            // Pagination logic
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
   setCurrentProducts(filtered.slice(indexOfFirstProduct, indexOfLastProduct));
    }, [products, value, priceRange, selectedColors, selectedSizes, sortOption,currentPage]); // Dependencies
  

 // const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

    // Handle price range change
    const handlePriceChange = (e) => {
        setPriceRange([e.target.value, priceRange[1]]);
      };
    
      const handleMaxPriceChange = (e) => {
        setPriceRange([priceRange[0], e.target.value]);
      };
      const handleFilterChange = (filterId,optionValue) => {

        if(filterId=='color' || filterId=='size'){
          setFilters((prevFilters) =>
            prevFilters.map((filter) => {
              if (filter.id === filterId) {
                // Update the correct filter group
                return {
                  ...filter,
                  options: filter.options.map((option) =>
                    option.value === optionValue
                      ? { ...option, checked: !option.checked } // Toggle the 'checked' value
                      : option
                  ),
                };
              }
              return filter; // Return the unchanged filters
            })
          );
        }
        if(filterId=='color'){
        
          setSelectedColors((prev) =>
            prev.includes(optionValue) ? prev.filter((c) => c !== optionValue) : [...prev, optionValue]
          );
        }
        if(filterId=='size'){
          setSelectedSizes((prev) =>
            prev.includes(optionValue) ? prev.filter((s) => s !== optionValue) : [...prev, optionValue]
          );
        }
      };
      const handleColorChange = (color) => {
        setSelectedColors((prev) =>
          prev.includes(color) ? prev.filter((c) => c !== color) : [...prev, color]
        );
      };
    
      const handleSizeChange = (size) => {
        setSelectedSizes((prev) =>
          prev.includes(size) ? prev.filter((s) => s !== size) : [...prev, size]
        );
      };
    //  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleSortChange = (value) => {
      // Update the sorting options, setting the current row to true and others to false
      const updatedOptions = sortingOptions.map((option) => ({
        ...option,
        current: option.value === value, // Set the clicked option to current, and the rest to false
      }));
      
      setSortingOptions(updatedOptions);
    setSortOption(value);
  };
  return (
    <div className="bg-white">
      <div>
        {/* Mobile filter dialog */}
        <Dialog open={mobileFiltersOpen} onClose={setMobileFiltersOpen} className="relative z-40 lg:hiddenold">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 z-40 flex">
            <DialogPanel
              transition
              className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
            >
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  onClick={() => setMobileFiltersOpen(false)}
                  className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>

              {/* Filters */}
              <form className="mt-4 border-t border-gray-200">
                {/* <h3 className="sr-only">Categories</h3>
                <ul role="list" className="px-2 py-3 font-medium text-gray-900">
                  {subCategories.map((category) => (
                    <li key={category.name}>
                      <a href={category.href} className="block px-2 py-3">
                        {category.name}
                      </a>
                    </li>
                  ))}
                </ul> */}
                <div className="px-4 py-6">
                <h3 className="text-sm font-medium text-gray-900">Price Range</h3>
                <div className="mt-4">
                  <div className="flex justify-between text-gray-500 text-sm">
                    <span>&#8377;{priceRange[0]}</span>
                    <span>&#8377;{priceRange[1]}</span>
                  </div>
                  <input
                    type="range"
                    min="0"
                    max="10000"
                    value={priceRange[0]}
                    onChange={handlePriceChange}
                    className="w-full mt-2"
                  />
                  <input
                    type="range"
                    min="0"
                    max="10000"
                    value={priceRange[1]}
                    onChange={handleMaxPriceChange}
                    className="w-full mt-2"
                  />
                </div>
              </div>
                {filters.map((section) => (
                  <Disclosure key={section.id} as="div" className="border-t border-gray-200 px-4 py-6">
                    <h3 className="-mx-2 -my-3 flow-root">
                      <DisclosureButton className="group flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                        <span className="font-medium text-gray-900">{section.name}</span>
                        <span className="ml-6 flex items-center">
                          <PlusIcon aria-hidden="true" className="h-5 w-5 group-data-[open]:hidden" />
                          <MinusIcon aria-hidden="true" className="h-5 w-5 [.group:not([data-open])_&]:hidden" />
                        </span>
                      </DisclosureButton>
                    </h3>
                    <DisclosurePanel className="pt-6">
                      <div className="space-y-6">
                        {section.options.map((option, optionIdx) => (
                          <div key={option.value} className="flex items-center">
                            <input
                              defaultValue={option.value}
                              defaultChecked={option.checked}
                              id={`filter-mobile-${section.id}-${optionIdx}`}
                              name={`${section.id}[]`}
                              onChange={(e) => {
                                debugger;
                              //  e.preventDefault(); // Prevent page reload
                                handleFilterChange(section.id,option.value); // Pass value to the handler handleFilterChange
                              }}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                              className="ml-3 min-w-0 flex-1 text-gray-500"
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </DisclosurePanel>
                  </Disclosure>
                ))}
              </form>
            </DialogPanel>
          </div>
        </Dialog>

        <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-4">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">{name}</h1>

            <div className="flex items-center">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <MenuButton className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    Sort
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    />
                  </MenuButton>
                </div>

                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <div className="py-1">
                    {sortingOptions.map((option) => (
                      <MenuItem key={option.name}>
                        <a
                          href={option.href}
                          value={option.value}                          
                          onClick={(e) => {
                            e.preventDefault(); // Prevent page reload
                            handleSortChange(option.value); // Pass value to the handler 
                          }}
                          className={classNames(
                            option.current ? 'font-medium text-gray-900' : 'text-gray-500',
                            'block px-4 py-2 text-sm data-[focus]:bg-gray-100',
                          )}
                        >
                          {option.name}
                        </a>
                      </MenuItem>
                    ))}
                  </div>
                </MenuItems>
              </Menu>

              <button type="button" className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7">
                <span className="sr-only">View grid</span>
                <Squares2X2Icon aria-hidden="true" className="h-5 w-5" />
              </button>
              <button
                type="button"
                onClick={() => setMobileFiltersOpen(true)}
                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hiddenold"
              >
                <span className="sr-only">Filters</span>
                <FunnelIcon aria-hidden="true" className="h-5 w-5" />
              </button>
            </div>
          </div>

          <section aria-labelledby="products-heading" className="pb-24 pt-6">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>

            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              {/* Filters */}
              <form className="hidden lg:block">
                {/* <h3 className="sr-only">Categories</h3>
                <ul role="list" className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900">
                  {subCategories.map((category) => (
                    <li key={category.name}>
                      <a href={category.href}>{category.name}</a>
                    </li>
                  ))}
                </ul> */}
{/* <h2 className="text-lg font-medium text-gray-900">Filters</h2> */}
                {filters.map((section) => (
                  <Disclosure key={section.id} as="div" className="border-b border-gray-200 py-6">
                    <h3 className="-my-3 flow-root">
                      <DisclosureButton className="group flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                        <span className="font-medium text-gray-900">{section.name}</span>
                        <span className="ml-6 flex items-center">
                          <PlusIcon aria-hidden="true" className="h-5 w-5 group-data-[open]:hidden" />
                          <MinusIcon aria-hidden="true" className="h-5 w-5 [.group:not([data-open])_&]:hidden" />
                        </span>
                      </DisclosureButton>
                    </h3>
                    <DisclosurePanel className="pt-6">
                      <div className="space-y-4">
                        {section.options.map((option, optionIdx) => (
                          <div key={option.value} className="flex items-center">
                            <input
                              defaultValue={option.value}
                              defaultChecked={option.checked}
                              id={`filter-${section.id}-${optionIdx}`}
                              name={`${section.id}[]`}
                              type="checkbox"
                              onChange={(e) => {
                                debugger;
                               // e.preventDefault(); // Prevent page reload
                                handleFilterChange(section.id,option.value); // Pass value to the handler 
                              }}
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label htmlFor={`filter-${section.id}-${optionIdx}`} className="ml-3 text-sm text-gray-600">
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </DisclosurePanel>
                  </Disclosure>
                ))}
              </form>

              {/* Product grid */}
              <div className="lg:col-span-3">{/* Your content */}
<ProductList products={currentProducts}></ProductList>
 {/* Pagination */}
 <div className="mt-6 w-full flex items-center justify-between sm:hidden">
        <button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          className={`relative inline-flex items-center rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 ${
            currentPage === 1
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-white hover:bg-gray-50"
          }`}
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className={`relative ml-3 inline-flex items-center rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 ${
            currentPage === totalPages
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-white hover:bg-gray-50"
          }`}
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{(currentPage - 1) * productsPerPage + 1}</span> to{" "}
            <span className="font-medium">{Math.min(currentPage * productsPerPage, filteredProducts.length)}</span> of{" "}
            <span className="font-medium">{filteredProducts.length}</span> results
          </p>
        </div>
        <div>
          <nav
            aria-label="Pagination"
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
          >
            <button
              onClick={handlePrevious}
              disabled={currentPage === 1}
              className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 ${
                currentPage === 1 ? "cursor-not-allowed" : "hover:bg-gray-50"
              }`}
            >
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            {/* Dynamically generate page numbers */}
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                  page === currentPage
                    ? "z-10 bg-blue-600 text-white"
                    : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                }`}
              >
                {page}
              </button>
            ))}

            <button
              onClick={handleNext}
              disabled={currentPage === totalPages}
              className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 ${
                currentPage === totalPages
                  ? "cursor-not-allowed"
                  : "hover:bg-gray-50"
              }`}
            >
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
              </div>
                   {/* Pagination */}
        {/* <div className="mt-6 flex justify-center space-x-2">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => setCurrentPage(page)}
              className={`px-4 py-2 border ${
                page === currentPage ? 'bg-indigo-500 text-white' : 'bg-white text-gray-900'
              }`}
            >
              {page}
            </button>
          ))}
        </div> */}


            </div>
          </section>
        </main>
      </div>
    </div>
  )
}



// import React, { useState } from 'react';
// import FilterSidebar from '../FilterSidebar';
// import ProductList from '../ProductList';

// const ProductHome = () => {
  
//     const [isSidebarOpen, setSidebarOpen] = useState(false);
//   return (
//     <div className="flex">
//       {/* Sidebar */}
//       <FilterSidebar isOpen={isSidebarOpen} toggleSidebar={() => setSidebarOpen(!isSidebarOpen)} />

//       {/* Main Content */}
//       <div className="flex-1 p-4">
//         <button
//           className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
//           onClick={() => setSidebarOpen(!isSidebarOpen)}
//         >
//           {isSidebarOpen ? "Hide Filters" : "Show Filters"}
//         </button>

//         <ProductList />
//       </div>
//     </div>
//   );
// };


// export default ProductHome;