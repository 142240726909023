// import React from 'react';
// import { useParams } from 'react-router-dom';

// const ProductDetail = () => {
//   const { id } = useParams();
  
//   // Simulated product data
//   const product = { id: id, name: `Product ${id}`, price: 29.99, imageUrl: 'https://via.placeholder.com/150', description: 'This is a great product.' };

//   return (
//     <div className="container mx-auto mt-10">
//       <div className="flex">
//         <img src={product.imageUrl} alt={product.name} className="h-64 w-64 object-cover" />
//         <div className="ml-10">
//           <h2 className="text-3xl font-bold">{product.name}</h2>
//           <p className="text-xl text-gray-700 mt-4">${product.price.toFixed(2)}</p>
//           <p className="mt-4">{product.description}</p>
//           <button className="mt-6 bg-indigo-500 text-white px-4 py-2 rounded">Add to Cart</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProductDetail;
// src/ProductDetails.js
import React, { useState,useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useCart } from './CartContext';
import { AiOutlineShoppingCart } from 'react-icons/ai';


const ProductDetails = () => {
  debugger;
  const location = useLocation();
  const {cart, addToCart ,removeFromCart } = useCart();
 
 
  const { product } = location.state || {}; // Get the passed product object
  const [selectedImage, setSelectedImage] = useState( `/assets/images/${product.imageUrl}.jpg`);

  const [zoomed, setZoomed] = useState(false);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const toggleZoom = () => {
    setZoomed(!zoomed);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

   // const { product } = location.state || {}; // Get the passed product object

    if (!product) {
      return <div>No product found</div>;
    }
    const isInCart = cart.some(cartItem => cartItem.id === product.id);

    const images = [
     {image: (product.imageUrl && product.imageUrl!== undefined?`/assets/images/${product.imageUrl}.jpg`:''),thumbnail:(product.imageUrl && product.imageUrl!== undefined?`/assets/thumbnail/${product.imageUrl} Thumb.jpg`:'')},
     {image: (product.subimage1 && product.subimage1!== undefined?`/assets/images/${product.subimage1}.jpg`:''),thumbnail:(product.subimage1 && product.subimage1!== undefined?`/assets/thumbnail/${product.subimage1} Thumb.jpg`:'')},
     {image: (product.subimage2 && product.subimage2!== undefined?`/assets/images/${product.subimage2}.jpg`:''),thumbnail:(product.subimage2 && product.subimage2!== undefined?`/assets/thumbnail/${product.subimage2} Thumb.jpg`:'')},
     {image: (product.subimage3 && product.subimage3!== undefined?`/assets/images/${product.subimage3}.jpg`:''),thumbnail:(product.subimage3 && product.subimage3!== undefined?`/assets/thumbnail/${product.subimage3} Thumb.jpg`:'')},
    ];
  //  setSelectedImage(images[0])
    const specifications = [
      { key: 'Length', value: product.specifications_Length },
      { key: 'Weight', value: product.specifications_Weight},
      { key: 'Finish', value: product.specifications_Finish },
      { key: 'Fastening', value: product.specifications_Fastening },
      { key: 'Color', value: product.specifications_Color },
    ];
  
    return(   
    <div className="flex flex-col md:flex-row p-6 bg-white">
      <div className="md:w-1/2old md:w-45p">
        <div className={`relative ${zoomed ? 'zoom' : ''}`} onClick={toggleZoom}>
          <img
            src={selectedImage}
            alt="Product"
            className={`w-full h-autoold h-96 rounded-lg transition-transform duration-300 ${zoomed ? 'scale-150' : ''}`}
          />
        </div>
        <div className="flex space-x-2 mt-4">
          {images.map((image, index) => (                     
           image.thumbnail ? (          
            <img
              key={index}
              src={image.thumbnail}
              // {`${imageUrl}-400.webp`}
              alt={`Thumbnail ${index + 1}`}
              className="w-24 h-24 rounded-lg cursor-pointer hover:opacity-75 transition-opacity duration-200"
              onClick={() => handleImageClick(image.image)}
            />
          ) : null // Render nothing if image is falsy
          ))}
        </div>
      </div>

      <div className="md:w-1/2 md:pl-6">
        <h2 className="text-2xl font-bold">{product.name}</h2>
        <p className="mt-2 text-gray-600">{product.description}</p>
        <p className="mt-4 text-xl font-semibold">{product.discountPrice}</p>
        {isInCart ? (
        <button  onClick={() => removeFromCart(product)} 
        className="w-full mt-2 py-2 px-4 bg-gray-100old bg-orange-500  text-white rounded-lg flex items-center justify-center space-x-2"
        // className="mt-4 bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors duration-200"
        >
 <AiOutlineShoppingCart className="text-xl" />
          Remove from Cart
        </button>): (
             <button  onClick={() => addToCart(product)}
             className="w-full mt-2 py-2 px-4 bg-gray-100  text-blue-600 rounded-lg flex items-center justify-center space-x-2"
              // className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors duration-200"
              >
                       <AiOutlineShoppingCart className="text-xl" />
             Add to Cart
           </button>
        )}

        {/* <div className="mt-6">
          <h3 className="text-xl font-semibold">Specifications</h3>
          <ul className="list-disc list-inside">
            {specifications.map((spec) => (
              <li key={spec.key}>
                <strong>{spec.key}:</strong> {(spec.value?spec.value:'N/A')}
              </li>
            ))}
          </ul>
        </div> */}
       
       
        </div>
      </div>
   
  );
};

export default ProductDetails;
